import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Col, Avatar, Divider, Row } from "antd";
import { Setting, } from "react-iconly";
import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import { details } from "../../../configs/details";
import { useSelector } from "react-redux";
import store from "../../../redux/store";

export default function HeaderUser() {
  const [image, setImage] = useState("")
  const profileData = useSelector((state) => state.profile.profileData);
  console.log("Outside UseEffect is Calling after Profile Pic Is Updated:", profileData.profilePic)
  store.subscribe(() => {
    const state = store.getState();
    console.log("Stateeeee", state.profile.profilePic)
    setImage(state.profile.profilePic)
    // Update the UI with the new state
  });

useEffect(()=> {
  console.log("UseEffect is Calling after Profile Pic Is Updated:", profileData.profilePic)

},[profileData.profilePic])
  const menu = (
    <div className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12" style={{ width: 260,  cursor: 'pointer' }}>
      <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">Profile Settings</span>

      <Link
        to="/account-and-settings"
        className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2"
      >
        View Profile
      </Link>

      {/* <Divider className="hp-mt-16 hp-mb-6" />

      <Row>
        <Col span={24}>
          <Link to="/account-and-settings" className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginLeft: -10, marginRight: -10 }}>
            <Setting
              set="curved"
              size={16}
            />

            <span className="hp-ml-8">Setting</span>
          </Link>
        </Col>

      </Row> */}

      <Divider className="hp-mb-16 hp-mt-6" />

      <Link to="/login" className="hp-p1-body" onClick={()=>{localStorage.clear()}}>
        Logout
      </Link>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar
    src={image ? image : details.profilePic}
        //  src={profileData.profilePic ? profileData.profilePic : details.profilePic} size={40} className="hp-cursor-pointer"
          />
      </Dropdown>
    </Col>
  );
};
