import * as actionTypes from "./profileTypes";

const INITIAL_STATE = {
  profileData: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    profilePic: '',
    type: 'admin',
  },
  profileModal: false,

};

   const customiseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.ADD_PROFILE:
    const { key, value} = action.payload;
    return {
      ...state,
      [key]: value
    }
      //  return state.profileData = action.payload;
        // break;
      case actionTypes.UPDATE_PROFILE:
       return state.profileData = {
          ...state.profileData,
          ...action.payload,
        };
        case actionTypes.OPEN_PROFILE_MODAL:
          return {
            ...state,
            profileModal: true,
          };
        case actionTypes.CLOSE_PROFILE_MODAL:
          return {
            ...state,
            profileModal: false,
          };
        // break;
      default:
        return state
        // break;
    }
  };
  
  export default customiseReducer;
