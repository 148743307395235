
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';

import { Row, Col } from "antd";
import Chart from "react-apexcharts";
import { IoMdArrowUp } from "react-icons/io";
export default function AvgOrder() {
  const [chartWidth, setChartWidth] = useState("50%")

  useEffect(() => {
    const timer = setTimeout(() => {
      setChartWidth("100%")
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  const [data] = useState({
    series: [
      {
        name: "Total Vendors",
        data: [50, 70, 100, 60],
      },
    ],
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: -12,
          bottom: -12,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          startingShape: "rounded",
          colors: {
            backgroundBarColors: [],
            backgroundBarRadius: 5,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#0010F7"],
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        max: 100,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    },
  });

 const LineChart = () => {
  const dataPoints = [
    [1609459200000, 50],
    [1609545600000, 70],
    [1609632000000, 100],
    [1609718400000, 60],
    [1609798400000, 65],
    [1609713000000, 80],
    [1609712000000, 90],
    // Add more data points as needed
  ];

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Total Orders",
        data: dataPoints,
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        toolbar: {
          show: false,
        },
        //height: 20,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val / 1000000).toFixed(0);
          },
        },
        title: {
          text: 'Price'
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false, // Set to false to hide x-axis labels
        },
        tickAmount: 0, // Set to 0 to hide x-axis ticks
        axisBorder: {
          show: false, // Set to false to hide x-axis stroke line
        },
        axisTicks: {
          show: false, // Set axisTicks.show option to false
        },
        showTicks: false, 
      },
      yaxis: {
        show: false, // Set to false to hide y-axis labels
      },
      grid: {
        show: false, // Set to false to hide the grid
      },
      toolbar: {
        show: false
      },
      tooltip: {
        show: false,
        y: {
          formatter: function (val) {
            return (val / 1000000).toFixed(0)
          }
        }
      }
    },
  });

  return (
    <div id="chart" style={{
      height:'60px',
       width:'80%', marginLeft:'10%'}}>
      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={100} />
    </div>
  );
};


  
  return (
    <div className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-6 hp-card-2" style={{minHeight:'140px'}}>
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-center" style={{paddingLeft:'15px', paddingRight:'5px'}}>
          <div
            id="chart"
            className="hp-bg-color-primary-4 hp-bg-color-dark-90 hp-border-radius hp-d-flex-center hp-px-18 hp-mb-18"
          >
          {/*   <Chart
              options={data.options}
              series={data.series}
              type="bar"
              width={chartWidth}
              height={80}
              legend="legend"
            /> */}
           
          </div>
          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-50" style={{fontSize:'17px', fontWeight:'bold', textAlign:'start'}}>Avg. Order Value</p>
          <div style={{display:'flex',alignItems:'baseline', marginTop:'7.5px'}}>
          <h4 className="hp-mb-0">13,346</h4>
          <p style={{marginBottom:'0px', marginLeft:'5px'}}><span style={{color:'green'}}>0.7%<IoMdArrowUp /></span><span>than last week</span></p>
          </div>
          <LineChart />
        </Col>
      </Row>
    </div>
  );
}
