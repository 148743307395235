import React, { useState } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Row, Col, Select, Button } from "antd";

import ActionButton from "./action";
import PageTitle from "./page-title";

import DashboardAnalytics from './analytics';
import DashboardSales from './sales';

export default function Dashboard() {
  return (
    <Row gutter={[32, 0]}>
      <Col span={24} className="hp-mt-0">
        <Row gutter={[32, 12]}>
          <Col span={24} className="hp-overflow-hidden">
            <Switch>
              <Route path="/" exact>
                <DashboardAnalytics />
              </Route>
              <Route path="/sales" exact>
                <DashboardSales />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
