import React, { useState } from "react";
import { Card, Row, Col, Divider, Button, Tooltip } from "antd";
import Chart from "react-apexcharts";
import LocationMap from "./../assets/UnitedArabEmirates.png";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"
import geoUrl from './features.json'

const WebsiteAudienceLocations = () => {
  const [selectedView, setSelectedView] = useState("UAE"); // Default view

  const countryViews = {
    UAE: [
      { country: "Abu Dhabi", views: 9624 /* Views data for Abu Dhabi */ },
      { country: "Dubai", views: 5673 /* Views data for Dubai */ },
      { country: "Ajman", views: 6343 /* Views data for Ajman */ },
      { country: "Umm Al-Quwain", views: 324 /* Views data for Umm Al-Quwain */ },
      { country: "Fujairah", views: 14 /* Views data for Fujairah */ },
      { country: "Ras Al Khaimah", views:4673/* Views data for Ras Al Khaimah */ },
    ],
    Region: [
      { country: "UAE", views: 9624 },
      { country: "Saudi", views: 5673 },
      { country: "Oman", views: 6343 },
      { country: "Qatar", views: 324 },
      { country: "Kuwait", views: 14 },
    ],
  };
  const markers = {
    UAE: [
    { markerOffset: -15, name: "Dubai", coordinates: [18.1193, -16.4897] },
    { markerOffset: 25, name: "Abu Dhabi", coordinates: [47.8825, 15.7942] },
    { markerOffset: 25, name: "Sharjah", coordinates: [-70.6693, -33.4489] },
    { markerOffset: 25, name: "Ras Al Khaimah", coordinates: [64.0721, 42.711] },
    { markerOffset: 25, name: "Ajman", coordinates: [-30.0721, 4.711] },
    { markerOffset: 25, name: "Fujairah", coordinates: [34.0721, 4.711] },
    { markerOffset: 25, name: "Umm Al Quwain", coordinates: [-4.0721, -44.711] },
    ],
    Region: [
      { markerOffset: -15, name: "UAE", coordinates: [18.1193, -16.4897] },
      { markerOffset: 25, name: "Saudi", coordinates: [47.8825, 15.7942] },
      { markerOffset: 25, name: "Oman", coordinates: [-70.6693, -33.4489] },
      { markerOffset: 25, name: "Qatar", coordinates: [74.0721, 4.711] },
      { markerOffset: 25, name: "Kuwait", coordinates: [-30.0721, 4.711] },
      ]
  };
  const colorr = '#fff'
  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  return (
    <Card className="hp-border-color-black-40 hp-card-6 hp-chart-text-color">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Divider className="hp-my-12" />
          <Row justify="space-between" align="bottom">
            <h4 className="hp-mr-8">By Top Visited Countries</h4>
              </Row>
              <Col span={24} >
              <Button style={{margin:'12px'}} onClick={() => handleViewChange("UAE")}>UAE</Button>
            <Button onClick={() => handleViewChange("Region")}>Region</Button>
        
              </Col>
        </Col>

        <Col span={24}>
         {/*  <img src={LocationMap} alt="Location Map" /> */}
         <ComposableMap
      /* projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [58, 20, 0],
        scale: 400
      }} */
    >
            {/* <PatternLines
    //    id='lines'
       height={100}
       width={100}
       stroke="black"
       strokeWidth={1}
       orientation={['vertical', 'horizontal']}
      > */}
            <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#EAEAEC"
              stroke="#D6D6DA"
            />
          ))
        }
      </Geographies>
      {markers[selectedView].map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize:'25px', marginTop:'4px' }}
          >
            {name}
          </text>
        </Marker>
      ))}
            {/* </PatternLines> */}
          </ComposableMap>
        </Col>

        <Col span={24}>
          <Divider className="hp-my-12" />
          {countryViews[selectedView].map(({ country, views }, index) => (
            <Row key={index}>
              <Col md={12} span={12}>
                {country}
              </Col>
              <Col md={12} span={12} className="hp-text-right">
                {views}
              </Col>
              {index < countryViews[selectedView].length - 1 && <Divider className="hp-my-12" />}
            </Row>
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default WebsiteAudienceLocations;
