import React from 'react'
import { Card, List, Avatar, Space, Typography, MailOutlined, UserOutlined, Divider } from 'antd';
import { MdOutlineMail } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { MdOutlineArrowDownward } from "react-icons/md";
import styled from 'styled-components';
import { Button } from 'antd';
import { GoCircleSlash } from "react-icons/go";
const { Title, Paragraph, Text } = Typography;


const CustomButton = styled(Button)`
  background-color: red;
  color: white;
  /* Add more styles as needed */
`;
const NewCustomers = () => {
    const data = [
        {
            title: 'Socrates Itumay',
            description: 'Customer ID#00223'
        },
        {
            title: 'Reyanate Labares',
            description: 'Customer ID#00221'
        },
        {
            title: 'Marianne Audrey',
            description: 'Customer ID#00220'
        },
        {
            title: 'Owen Bongcaras',
            description: 'Customer ID#00219'
        },
        {
            title: 'Kirby Avendula',
            description: 'Customer ID#00218'
        },
    ];
    return (
        <div className='vendor-cards'>
            <Card title="New Customers" bordered={false}>
                <style>
                    {`
                   .vendor-cards .ant-list-item {
                      padding: 9px 0px;
                    }
                    .vendor-cards .ant-avatar {
                        height: 45px;
                        width: 45px;
                    }
                    .vendor-cards .ant-list-item-meta-avatar {
                        margin-right: 12px;
                    }
                   
                    .vendor-cards .ant-list-item-meta-title {
                        margin-bottom: 4px;
                        color: #2d3436;
                        font-size: 14px;
                        line-height: 1.13715;
                    }
                    `}
                </style>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                                title={<a href="https://ant.design">{item.title}</a>}
                                description={item.description}
                            />
                            <Space>
                                <MdOutlineMail style={{ fontSize: '22px', color: 'rgba(0, 0, 0, 0.45)' }} />
                                <GoCircleSlash style={{ fontSize: '22px', color: 'rgba(0, 0, 0, 0.45)' }} />
                                <FiUser style={{ fontSize: '22px', color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Space>
                        </List.Item>
                    )}
                />
                <Divider />
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'rgba(0, 0, 0, 0.45)' 
                }}>
                    <Typography>
                        <Text style={{color: 'rgba(0, 0, 0, 0.45)' }}>View All Customers </Text>
                    </Typography>
                    <MdOutlineArrowDownward style={{ fontSize: '18px'}} />
                </div>
            </Card>
        </div>
    )
}

export default NewCustomers
