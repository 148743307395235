import {
  Home,
  Work,
  Scan,
  Bag2,
  Filter2,
  Discount,
  Category,
  Swap,
  Wallet,
  Buy,
  People,
  Paper,
  Discovery,
  Message,
  Bookmark,
  TwoUsers,
  Chat,
  Graph,
  Setting,
  Filter,
  Game,
} from "react-iconly";

const main = [
  // {
  //     id: "dashboard",
  //     title: "Dashboard",
  //     navLink: "/",
  //     icon: <Home set="curved" className="remix-icon" />,
  // },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home set="curved" className="remix-icon" />,
    children: [
      {
        id: "dt-analytics",
        title: "Analytics",
        navLink: "/dt-analytics",
      },
      {
        id: "dt-sales",
        title: "Sales",
        navLink: "/dt-sales",
      },
      {
        id: "dt-vendors",
        title: "Vendors",
        navLink: "/dt-vendors",
      },
      {
        id: "dt-helpdesk",
        title: "Helpdesk",
        navLink: "/dt-helpdesk",
      },
      {
        id: "dt-courier",
        title: "Courier",
        navLink: "/dt-courier",
      },
    ],
  },

  // <Divider className="da-border-color-black-20 da-border-color-dark-70 da-mt-0" />
  // HR
  {
    id: "orders",
    title: "Orders",
    navLink: "/orders",
    icon: <Wallet set="curved" className="remix-icon" />,
    
    children: [
      {
        id: "reviews",
        title: "Reviews",
        navLink: "/reviews",
        icon: <Scan set="curved" className="remix-icon" />,
      },
    ],
  },
  /* {
    id: "auction",
    title: "Auction",
    navLink: "/Auction",
    icon: <Wallet set="curved" className="remix-icon" />,
  }, */
  /* {
    id: "reviews",
    title: "Reviews",
    navLink: "/reviews",
    icon: <Message set="curved" className="remix-icon" />,
  }, */
  {
    id: "withdrawals",
    title: "Withdrawals",
    navLink: "/withdrawals",
    icon: <Buy set="curved" className="remix-icon" />,
  },
  {
    id: "helpdesk",
    title: "Helpdesk",
    navLink: "/helpdesk",
    icon: <Chat set="curved" className="remix-icon" />,
  },
  /*  */
 /*  {
    id: "store-management",
    title: "Store Management",
    icon: <Filter set="curved" className="remix-icon" />,
    children: [
      {
        id: "products",
        title: "Products",
        navLink: "/products",
        icon: <Scan set="curved" className="remix-icon" />,
      },
    ],
    }, */
  // HR
  {
    id: "store-management",
    title: "Store Management",
    icon: <Filter set="curved" className="remix-icon" />,
    children: [
      {
        id: "products",
        title: "Products",
        navLink: "/products",
        icon: <Scan set="curved" className="remix-icon" />,
      },
      {
        id: "attributes",
        title: "Attributes",
        navLink: "/attributes",
        icon: <Filter2 set="curved" />,
      },
      {
      id: 'variations',
      title: 'Variations',
      navLink: '/variations',
      icon: <Filter2 set="curved" />
      },
      {
        id: 'specification',
        title: 'Specifications/features/attribute',
        navLink: '/specification',
        icon: <Filter2 set="curved" />
        },
      {
        id: "brands",
        title: "Brands",
        navLink: "/brands",
        icon: <Bag2 set="curved" className="remix-icon" />,
      },
      {
        id: "categories",
        title: "Categories",
        navLink: "/categories",
        icon: <Category set="curved" className="remix-icon" />,
      },
      {
        id: "coupons",
        title: "Coupons ",
        navLink: "/coupons",
        icon: <Discount set="curved" className="remix-icon" />,
      },
      {
        id: "promotions",
        title: "Promotions",
        navLink: "/promotions",
        icon: <Discount set="curved" className="remix-icon" />,
      },
    ],
  },
  //auction
  {
    id: "auction-section",
    title: "Auction",
    icon: <Filter set="curved" className="remix-icon" />,
    children: [
      {
        id: "auction",
        title: "Add Auction Product",
        navLink: "/auction-products",
        icon: <Scan set="curved" className="remix-icon" />,
      },
      {
        id: "admin-auction-products",
        title: "Admin Auction Product",
        navLink: "/admin-auction-product",
        icon: <Filter2 set="curved" />,
      },
      {
        id: "vendor-auction-products",
        title: "Vendor Auction Products",
        navLink: "/vendor-auction-products",
        icon: <Bag2 set="curved" className="remix-icon" />,
      },
      {
        id: "admin-sale",
        title: "Admin Sale",
        navLink: "/admin-sale",
        icon: <Category set="curved" className="remix-icon" />,
      },
      {
        id: "vendor-sale",
        title: "Vendor Sale ",
        navLink: "/vendor-sale",
        icon: <Discount set="curved" className="remix-icon" />,
      },
      {
        id: "auction-option-for-employees/subadmin",
        title: "Auction Option For Employees/Subadmin",
        navLink: "/auction-option-for-employees/subadmin",
        icon: <Discount set="curved" className="remix-icon" />,
      },
    ],
  },

  // HR
  {
    id: "user-management",
    title: "User Management",
    icon: <TwoUsers set="curved" className="remix-icon" />,
    children: [
      {
        id: "customers",
        title: "Customers",
        navLink: "/customers",
        icon: <People set="curved" className="remix-icon" />,
      },
      {
        id: "employees/subadmin",
        title: "Employees/subadmin",
        navLink: "/employees-subadmins",
        icon: <People set="curved" className="remix-icon" />,

      },
      {
        id: "vendors",
        title: "Vendors",
        navLink: "/vendors",
        icon: <Work set="curved" className="remix-icon" />,
        /* children: [
          {
            id: "vendor-approvals",
            title: "Vendor Approval",
            navLink: "/vendor-approval",
            icon: <People set="curved" className="remix-icon" />,
          },
        ] */
      },{
      id: "vendor-approvals",
      title: "Vendor Approval",
      navLink: "/vendor-approval",
      icon: <People set="curved" className="remix-icon" />,
      },
      {
        id: "courier",
        title: "Courier",
        navLink: "/courier",
        icon: <Work set="curved" className="remix-icon" />,
      },
      {
        id: "users",
        title: "Users",
        navLink: "/users",
        icon: <TwoUsers set="curved" className="remix-icon" />,
      },
    ],
  },

  // HR
  {
    id: "reports",
    title: "Reports",
    icon: <Graph set="curved" />,
    navLink: "/reports",
   /*  children: [
      {
        id: "orders-report",
        title: "Orders",
        navLink: "/reports/orders",
      },
      {
        id: "products-report",
        title: "Products",
        navLink: "/reports/products",
      },
    ], */
  },
  
  {
    id: "accounts",
    title: "Accounts",
    navLink: "/accounts",
    icon: <Wallet set="curved" className="remix-icon" />,
  },
  // HR
  {
    id: "system",
    title: "System",
    icon: <Setting set="curved" className="remix-icon" />,
    children: [
      {
        id: "pages",
        title: "Pages",
        navLink: "/pages",
        icon: <Paper set="curved" className="remix-icon" />,
      },
      {
        id: "navigation",
        title: "Navigation",
        navLink: "/navigations",
        icon: <Discovery set="curved" className="remix-icon" />,
      },
      {
        id: "store-settings",
        title: "Store Settings",
        navLink: "/store-settings",
        icon: <Game set="curved" className="remix-icon" />,
      },
      {
        id: "shipping",
        title: "Shipping",
        navLink: "/shipping",
        icon: <Swap set="curved" className="remix-icon" />,
      },
      {
        id: "knowledge-base",
        title: "Knowledge Base",
        navLink: "/knowledge-base",
        icon: <Bookmark set="curved" className="remix-icon" />,
      },
      // {
      //     id: "email-templates",
      //     title: "E-mail Templates",
      //     navLink: "/email-templates",
      //     icon: <Message set="curved" className="remix-icon" />,
      // },
    ],
  },
];

export default main;
