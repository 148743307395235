import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Col, Divider, Row } from 'antd';

const generateDayWiseTimeSeries = (baseDate, count, range) => {
  const seriesData = [];

  for (let i = 0; i < count; i++) {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + i);

    seriesData.push({
      x: date.getTime(),
      y: Math.floor(Math.random() * (range.max - range.min + 1) + range.min),
    });
  }

  return seriesData;
};

const RealTimeSales = () => {
    
      const [chartData] = useState({
        series: [
          { data: [44, 55, 41, 64, 22, 43, 21] },
          { data: [53, 32, 33, 52, 13, 44, 32] },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 430,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'bottom',
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          /* dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff'],
            },
          }, */
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
          },
        },
      });
    
  return (
    <div id="chart">
      <Card>
        <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>Real-Time Sales</h4>
        <Divider />
        <Row gutter={[32,32]}>
          <Col span={12}>
            <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>AED 620,076</h4>
          {/*   <p style={{ fontWeight: 'bolder' }}>MRR Growth</p> */}
            <p style={{ color: '#aeafb0' }}>Total Sales</p>
          </Col>
          <Col span={12}>
            <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>AED 620,076</h4>
           {/*  <p style={{ fontWeight: 'bold' }}>AVG. MRR/Customer</p> */}
            <p style={{ color: '#aeafb0' }}>AVG. Sales Per Day</p>
          </Col>
        </Row>
        <Chart options={chartData.options} series={chartData.series} type="bar" height={430} />
       
      </Card>
    </div>
  );
};

export default RealTimeSales;
