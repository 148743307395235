import React, { useState } from "react";

import { Card, Row, Col, Divider, Button } from "antd";

export default function AllOrders() {

  return (
    <Card className="hp-border-color-black-40 hp-card-3 hp-chart-text-color">
      <Row>
        <Col className="hp-mb-8" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Orders</h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <Button type="link" className="hp-p-0" href="/orders">
            See all orders
          </Button>
          <Divider className="hp-my-12" />
            <Row>
              <Col md={12} span={12}>Return</Col>
              <Col md={12} span={12} className="hp-text-right">14</Col>
            </Row>
          <Divider className="hp-my-12" />
            <Row>
              <Col md={12} span={12}>Completed</Col>
              <Col md={12} span={12} className="hp-text-right">2478</Col>
            </Row>
          <Divider className="hp-my-12" />
            <Row>
              <Col md={12} span={12}>Awaiting Payment</Col>
              <Col md={12} span={12} className="hp-text-right">257</Col>
            </Row>
          <Divider className="hp-my-12" />
            <Row>
              <Col md={12} span={12}>In-Progress</Col>
              <Col md={12} span={12} className="hp-text-right">0</Col>
            </Row>
          <Divider className="hp-my-12" />
            <Row>
              <Col md={12} span={12}>Cancelled</Col>
              <Col md={12} span={12} className="hp-text-right">14</Col>
            </Row>
          
        </Col>
      </Row>
    </Card>
  );
}
