import { useSelector } from "react-redux";

import { Button, ConfigProvider, Modal } from "antd";
import COGNITO from "./configs/awsConfigs";
import { Amplify } from "aws-amplify";

import Router from "./router/Router";
Amplify.configure({
  aws_user_pools_id: COGNITO.ref.Auth.userPoolId,
  aws_user_pools_web_client_id: COGNITO.ref.Auth.userPoolWebClientId,
});

export default function App() {
  const customise = useSelector((state) => state.customise);
  const authStatus = useSelector((state) => state.auth.isAuthorized);
console.log("%c Auth Status","background: pink; color: black", authStatus)
  return (
    <ConfigProvider direction={customise.direction}>
      <Modal visible={authStatus} footer centered closable={false}>
        <div className="session_modal">
          <p>Your session has been expired, please log in again.</p>
          <Button
            type="primary"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </Button>
        </div>
      </Modal>
      <Router />
    </ConfigProvider>
  );
}
