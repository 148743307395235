export const ADD_PROFILE = "ADD_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const OPEN_PROFILE_MODAL = "OPEN_PROFILE_MODAL";
export const CLOSE_PROFILE_MODAL = "CLOSE_PROFILE_MODAL";

// export const INCREMENT_ONBOARD_COUNTER = "INCREMENT_ONBOARD_COUNTER";
// export const DECREMENT_ONBOARD_COUNTER = "DECREMENT_ONBOARD_COUNTER";
// export const RESET_ONBOARD_COUNTER = "RESET_ONBOARD_COUNTER";
// export const UPDATE_TYPE = "UPDATE_TYPE";
// export const ONBOARD_DOCUMENTS = "ONBOARD_DOCUMENTS";
