import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Col, Divider, Row } from 'antd';

const generateDayWiseTimeSeries = (baseDate, count, range) => {
  const seriesData = [];

  for (let i = 0; i < count; i++) {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + i);

    seriesData.push({
      x: date.getTime(),
      y: Math.floor(Math.random() * (range.max - range.min + 1) + range.min),
    });
  }

  return seriesData;
};

const AccountMonthlyRecurringRevenueGrowth = () => {
  const [chartData] = useState({
    series: [
      {
        name: 'Growth Actual',
        data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 60,
        }),
      },
      {
        name: 'Actual',
        data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 20,
        }),
      },
      {
        name: 'Plan',
        data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
          min: 10,
          max: 15,
        }),
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 350,
        stacked: true,
        events: {
          selection: (chart, e) => {
            console.log(new Date(e.xaxis.min));
          },
        },
        toolbar: {
          show: false, // Remove the toolbar
        },
      },
      colors: ['#008FFB', '#00E396', '#CED4DC'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      xaxis: {
        type: 'datetime',
      },
    },
  });

  return (
    <div id="chart">
      <Card>
        <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>Account & Monthly Recurring Revenue Growth</h4>
        <Divider />
        <Row gutter={[32,32]}>
          <Col span={12}>
            <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>$620,076</h4>
            <p style={{ fontWeight: 'bolder' }}>MRR Growth</p>
            <p style={{ color: '#aeafb0' }}>Measure how fast you're growing monthly Recurring revenue. Learn More</p>
          </Col>
          <Col span={12}>
            <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>$620,076</h4>
            <p style={{ fontWeight: 'bold' }}>AVG. MRR/Customer</p>
            <p style={{ color: '#aeafb0' }}>Measure how fast you're growing monthly Recurring revenue. Learn More</p>
          </Col>
        </Row>
        <Chart options={chartData.options} series={chartData.series} type="area" height={350} />
      </Card>
    </div>
  );
};

export default AccountMonthlyRecurringRevenueGrowth;
