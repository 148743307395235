import {
    CloseSquare,
    Discount,
    User,
    Paper,
    InfoSquare,
    Password,
    PaperPlus,
    Unlock,
    Bookmark,
    Message,
} from "react-iconly";


const pages = [
    {
        header: "Products",
        navLink: "/products",
    },
    {
        header: "Orders",
        navLink: "/orders",
    },
    {
        header: "Reviews",
        navLink: "/reviews",
    },
    {
        header: "Finance",
        navLink: "/finance",
    },
    {
        header: "Reports",
        navLink: "/reports",
    },
    // {
    //     header: "Reports",
    //     subMenu: [
    //         {
    //             id: "knowledge-base",
    //             title: "Knowledge Base",
    //             navLink: "/pages/knowledge-base/knowledge-base-2",
    //         }
    //     ]
    // },
];

export default pages