import React from "react";
import { Card, Row, Col, Divider } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";

import LocationMap from "./../assets/UnitedArabEmirates.png";

const locationData = [
  { name: "UAE", orders: 156, earnings: 9624 },
  { name: "Saudi", orders: 156, earnings: 5673 },
  { name: "Omar", orders: 156, earnings: 6343 },
  { name: "Qatar", orders: 156, earnings: 324 },
  { name: "Kuwait", orders: 156, earnings: 5897 },
];

export default function TopPerformingLocations() {
  return (
    <Card className="hp-border-color-black-40 hp-card-6 hp-chart-text-color">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Top Performing Countries</h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <img src={LocationMap} style={{ height: "280px" }} alt="Location Map" />
        </Col>

        <Col span={24}>
          <Divider className="hp-my-12" />
          <Row>
            <Col md={8} span={8} style={{ color: "#ffab43" }}>
              Locations
            </Col>
            <Col md={8} span={8} style={{ color: "#ffab43" }}>
              Orders
            </Col>
            <Col md={8} span={8} className="hp-text-right" style={{ color: "#ffab43" }}>
              Earnings
            </Col>
          </Row>
          <Divider className="hp-my-12" />
          {locationData.map((location, index) => (
            <Row key={index}>
              <Col md={8} span={8}>
                {location.name}
              </Col>
              <Col md={8} span={8}>
                {location.orders}
              </Col>
              <Col md={8} span={8} className="hp-text-right">
                AED {location.earnings}
              </Col>
              {index !== locationData.length - 1 && <Divider className="hp-my-12" />}
            </Row>
          ))}
        </Col>
      </Row>
    </Card>
  );
}
