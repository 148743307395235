import * as actionTypes from "./productTypes";

const INITIAL_STATE = {
  products: { list: { products: [] } },
  categories: [],
  userProduct: { salePrice: 0 },
  brands: [],
  search: "",
  form: {},
  isProductCreate: true,
  attributue: [],
  counter: 1,
  ticketModal: false,
  ticketData: {
    title: "",
    description: "",
    ticketBy: "seller",
    ticketFor: "",
    type: "report",
    visibility: "private",
  },
};

const customiseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case actionTypes.ADD_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case actionTypes.ADD_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };

    case actionTypes.ADD_BRAND:
      return {
        ...state,
        brands: action.payload,
      };

    case actionTypes.USE_PRODUCT:
      return {
        ...state,
        userProduct: action.payload,
      };
    case actionTypes.PRODUCT_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case actionTypes.IS_PRODUCT_CREATE:
      return {
        ...state,
        isProductCreate: action.payload,
      };
    case actionTypes.ADD_ATTRIBUTE:
      return {
        ...state,
        attribute: action.payload,
      };
    case actionTypes.PRODUCT_INCREMENT:
      return {
        ...state,
        counter: state.counter + 1,
      };
    case actionTypes.PRODUCT_DECREMENT:
      return {
        ...state,
        counter: state.counter > 1 ? state.counter - 1 : state.counter,
      };
    case actionTypes.RESET_PRODUCT_COUNTER:
      return {
        ...state,
        counter: 0,
      };
    case actionTypes.OPEN_TICKET_MODAL:
      return {
        ...state,
        ticketModal: true,
      };
    case actionTypes.CLOSE_TICKET_MODAL:
      return {
        ...state,
        ticketModal: false,
      };
    case actionTypes.UPDATE_TICKET:
      return {
        ...state,
        ticketData: action.payload,
      };
    default:
      return state;
  }
};

export default customiseReducer;
