import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";

import customiseReducer from "./customise/customiseReducer";
import footerReducer from "./footer/footerReducer";
import orderReducers from "./order/orderReducer";
import productReducer from "./product/productReducer";
import profileReducer from "./profile/profileReducer";
const rootReducer = combineReducers({
  customise: customiseReducer,
  products: productReducer,
  profile: profileReducer,
  footer: footerReducer,
  auth: authReducer,
  orders: orderReducers,
});

export default rootReducer;
