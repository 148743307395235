import React from "react";

import { Card, Row, Col, Table, Button, Tag } from "antd";
import { Download } from "react-iconly";

export default function LatestOrders() {
  const columns = [
    {
      title: (
        <span className="hp-badge-size hp-font-weight-400 hp-text-color-black-80 hp-text-uppercase">
          Order Number
        </span>
      ),
      dataIndex: "ordernumber",
      key: "ordernumber",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-0">{text}</p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-400 hp-text-color-black-80 hp-text-uppercase">
          Date
        </span>
      ),
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-0">{text}</p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-400 hp-text-color-black-80 hp-text-uppercase">
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-0">{text}</p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-400 hp-text-color-black-80 hp-text-uppercase">
          Delivery Status
        </span>
      ),
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      render: (text) => (
        <span className="hp-d-block">{text}</span>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-400 hp-text-color-black-80 hp-text-uppercase">
          Status
        </span>
      ),
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag;
            if (tag === "Refund") {
              color = "warning";
            } else if (tag === "Charge Back") {
              color = "error";
            } else {
              color = "success";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      date: "June 9,2020",
      ordernumber: "7986",
      orderlink:"",
      amount: "AED 1,322.45",
      tags: ["Paid"],
      deliveryStatus:"Delivered",
    },
    {
      key: "2",
      date: "June 9,2020",
      ordernumber: "1478",
      orderlink:"",
      amount: "AED 733.32",
      tags: ["Paid"],
      deliveryStatus:"In-Progress",
    },
    {
      key: "3",
      date: "June 10,2020",
      ordernumber: "3258",
      orderlink:"",
      amount: "AED 1,923.32",
      tags: ["Refund"],
      deliveryStatus:"Returned",
    },
    {
      key: "4",
      date: "June 9,2020",
      ordernumber: "14789",
      orderlink:"",
      amount: "AED 922.12",
      tags: ["Paid"],
      deliveryStatus:"Delivered",
    },
    {
      key: "5",
      date: "June 10,2020",
      ordernumber: "5897",
      orderlink:"",
      amount: "AED 323.98",
      tags: ["Charge Back"],
      deliveryStatus:"Delivered",
    },
    {
      key: "1",
      date: "June 9,2020",
      ordernumber: "7986",
      orderlink:"",
      amount: "AED 1,322.45",
      tags: ["Paid"],
      deliveryStatus:"Delivered",
    },
    {
      key: "2",
      date: "June 9,2020",
      ordernumber: "1478",
      orderlink:"",
      amount: "AED 733.32",
      tags: ["Paid"],
      deliveryStatus:"In-Progress",
    },
    {
      key: "3",
      date: "June 10,2020",
      ordernumber: "3258",
      orderlink:"",
      amount: "AED 1,923.32",
      tags: ["Refund"],
      deliveryStatus:"Returned",
    },
    {
      key: "4",
      date: "June 9,2020",
      ordernumber: "14789",
      orderlink:"",
      amount: "AED 922.12",
      tags: ["Paid"],
      deliveryStatus:"Delivered",
    },
    {
      key: "5",
      date: "June 10,2020",
      ordernumber: "5897",
      orderlink:"",
      amount: "AED 323.98",
      tags: ["Charge Back"],
      deliveryStatus:"Delivered",
    },
  ];

  return (
    <Card className="hp-border-color-black-40 hp-project-ecommerce-table-card">
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between" className="hp-mb-8">
            <h4 className="hp-mb-0">Latest Orders</h4>

            <Button type="link" className="hp-p-0 hp-text-color-black-80 hp-hover-text-color-black-100" style={{minHeight:'auto', lineHeight:'normal'}}
            href="/orders">
              See all Orders
            </Button>
          </Row>

          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 700 }}
          />
        </Col>
      </Row>
    </Card>
  );
}