import React from 'react'
import { Card, List, Avatar, Space, Typography, MailOutlined, UserOutlined, Divider } from 'antd';
import { MdOutlineMail } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { MdOutlineArrowDownward } from "react-icons/md";
import styled from 'styled-components';
import { Button } from 'antd';
import { FaRegStar } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { RxCrossCircled } from "react-icons/rx";
import { IoCarOutline } from "react-icons/io5";

const { Title, Paragraph, Text } = Typography;


const CustomButton = styled(Button)`
  background-color: red;
  color: white;
  /* Add more styles as needed */
`;
const MostRecentOrders = () => {
    const data = [
        {
            title: 'Orders from #10322',
            description: 'May 21, 2019, 3:30pm',
            status: 'Completed',
            amount: 250.00,
            icon: <GrStatusGood />
        },
        {
            title: 'Orders from #00910',
            description: 'May 21, 2019, 3:30pm',
            status: 'Completed',
            amount: 164.00,
            icon: <GrStatusGood />


        },
        {
            title: 'Orders from #44333',
            description: 'May 21, 2019, 3:30pm',
            status: 'For pickup',
            amount: 250.00,
            icon: <IoCarOutline />
        },
        {
            title: 'Orders from #023328',
            description: 'May 21, 2019, 3:30pm',
            status: 'Completed',
            amount: 250.00,
            icon: <GrStatusGood />


        },
        {
            title: 'Orders from #10322',
            description: 'May 21, 2019, 3:30pm',
            status: 'Declined',
            amount: 250.00,
            icon: <RxCrossCircled />

        },
    ];
    return (
        <div className='recent-orders-cards'>
            <Card title="Most Recent Orders" bordered={false}>
                <style>
                    {`
                   .recent-orders-cards .ant-list-item {
                      padding: 9px 0px;
                    }
                    .recent-orders-cards .ant-avatar {
                        height: 53px;
                        width: 45px;
                    }
                    .recent-orders-cards .ant-list-item-meta-avatar {
                        margin-right: 12px;
                    }
                   .recent-orders-cards .ant-avatar {
                    align-items: baseline;
                   }
                    .recent-orders-cards .ant-list-item-meta-title {
                    margin-bottom: 4px;
                   color: #2d3436;
                 font-size: 14px;
                 line-height: 1.13715;
                 } 
                    `}
                </style>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={item.icon} />}
                                title={<a href="https://ant.design">{item.title}</a>}
                                description={item.description}
                            />
                            <Space>
                                <div>
                                    <p style={{ marginBottom: '0px' }}>AED {item.amount}</p>
                                    {/*   <MdOutlineMail style={{ fontSize: '22px', color: 'rgba(0, 0, 0, 0.45)' }} /> */}
                                    <p style={{ color: item.status === 'Completed' ? 'green' : item.status === 'For pickup' ? 'blue' : 'Declined' }}>
                                        {item.status}
                                    </p>
                                </div>

                            </Space>
                        </List.Item>
                    )}
                />
                <Divider />
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'rgba(0, 0, 0, 0.45)'
                }}>
                    <Typography>
                        <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>View All Vendors</Text>
                    </Typography>
                    <MdOutlineArrowDownward style={{ fontSize: '18px' }} />
                </div>
            </Card>
        </div>
    )
}

export default MostRecentOrders
