import { lazy } from "react";

const PagesRoutes = [
  // Auth
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "VerticalLayoutPublic",
    private: false,
  },
  {
    path: "/recover-password",
    component: lazy(() =>
      import("../../view/pages/authentication/recover-password")
    ),
    layout: "VerticalLayoutPublic",
    private: false,
  },
  {
    path: "/reset-password",
    component: lazy(() =>
      import("../../view/pages/authentication/reset-password")
    ),
    layout: "VerticalLayoutPublic",
    private: false,
  },

  // Dashboard
  {
    path: "/dt-analytics",
    component: lazy(() => import("../../view/home/dashboard/analytics")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/dt-sales",
    component: lazy(() => import("../../view/home/dashboard/sales")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/dt-vendors",
    component: lazy(() => import("../../view/home/dashboard/vendors")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/dt-helpdesk",
    component: lazy(() => import("../../view/home/dashboard/helpdesk")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/dt-courier",
    component: lazy(() => import("../../view/home/dashboard/courier")),
    layout: "VerticalLayout",
    private: true,
  },

  // Internal
  {
    path: "/products",
    component: lazy(() => import("../../view/pages/products/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/variations",
    component: lazy(() => import("../../view/pages/variations/view")),
    layout: "VerticalLayout",
    private: true,
  },
  
  {
    path: "/specification",
    component: lazy(() => import("../../view/pages/specifications/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-product/:id",
    component: lazy(() => import("../../view/pages/products/add")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-product",
    component: lazy(() => import("../../view/pages/products/add")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add/Preview/:id",
    component: lazy(() => import("../../view/pages/products/add/Preview")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/brands",
    component: lazy(() => import("../../view/pages/brands/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-brand",
    component: lazy(() => import("../../view/pages/brands/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/edit-brand/:id",
    component: lazy(() => import("../../view/pages/brands/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/details/:id",
    component: lazy(() => import("../../view/pages/support/details/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/categories",
    component: lazy(() => import("../../view/pages/categories/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/CategoriesPreview/:id",
    component: lazy(() =>
      import("../../view/pages/categories/add/CategoryPreview")
    ),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-category",
    component: lazy(() => import("../../view/pages/categories/add/index")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/edit-category/:id",
    component: lazy(() => import("../../view/pages/categories/add/index")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/coupons",
    component: lazy(() => import("../../view/pages/coupons/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/promotions",
    component: lazy(() => import("../../view/pages/promotions/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-coupons",
    component: lazy(() => import("../../view/pages/coupons/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-promotions",
    component: lazy(() => import("../../view/pages/promotions/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/edit-coupons/:id",
    component: lazy(() => import("../../view/pages/coupons/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/navigations",
    component: lazy(() => import("../../view/pages/navigations/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages",
    component: lazy(() => import("../../view/pages/pagesPage/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-page",
    component: lazy(() => import("../../view/pages/pagesPage/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/edit-page/:id",
    component: lazy(() => import("../../view/pages/pagesPage/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/page-preview/:id",
    component: lazy(() => import("../../view/pages/pagesPage/add/preview")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/shipping/cost/:id",
    component: lazy(() => import("../../view/pages/shippings/view/costs")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/shipping",
    component: lazy(() => import("../../view/pages/shippings/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-shipping",
    component: lazy(() => import("../../view/pages/shippings/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/edit-shipping/:id",
    component: lazy(() => import("../../view/pages/shippings/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/knowledge-base",
    component: lazy(() => import("../../view/pages/knowledgeBase/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/SingleKnowledgeBase",
    component: lazy(() =>
      import("../../view/pages/knowledgeBase/view/singleKnowledgeBase")
    ),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/Add",
    component: lazy(() => import("../../view/pages/knowledgeBase/add/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/attributes/:id",
    component: lazy(() =>
      import("../../view/pages/attributes/view/subAttributes")
    ),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/attributes",
    component: lazy(() => import("../../view/pages/attributes/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/add-attributes",
    component: lazy(() => import("../../view/pages/attributes/add/index")),
    layout: "VerticalLayout",
    private: true,
  },

  // /add-product/select-product
  // /add-product/step-1
  // /add-product/step-2
  // /add-product/preview
  {
    path: "/orders/:orderId/product/:productId",
    component: lazy(() => import("../../view/pages/orders/detail")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/orders",
    component: lazy(() => import("../../view/pages/orders/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/auction-products",
    component: lazy(() => import("../../view/pages/auction/view/addProduct")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/admin-auction-product",
    component: lazy(() => import("../../view/pages/auction/view/adminAuctionProduct")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/admin-auction/:id",
    component: lazy(() => import("../../view/pages/auction/pages/AdminAuctionProducts/SingleAdminAuctionProduct")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/vendor-auction-products",
    component: lazy(() => import("../../view/pages/auction/view/vendorAuctionProduct")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/admin-sale",
    component: lazy(() => import("../../view/pages/auction/view/adminSale")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/vendor-sale",
    component: lazy(() => import("../../view/pages/auction/view/VendorSale")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/auction-option-for-employees/subadmin",
    component: lazy(() => import("../../view/pages/auction/view/auctionOptionForEmployees&Subadmin")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/accounts",
    component: lazy(() => import("../../view/pages/accounts/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/reviews",
    component: lazy(() => import("../../view/pages/reviews/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/helpdesk",
    component: lazy(() => import("../../view/pages/support/view/index")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/createTicket",
    component: lazy(() => import("../../view/pages/support/add/createTicket")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/edit-ticket/:id",
    component: lazy(() => import("../../view/pages/support/add/createTicket")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/withdrawals",
    component: lazy(() => import("../../view/pages/withdrawals/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/withdrawals-detail/:id",
    component: lazy(() => import("../../view/pages/withdrawals/view/detail")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/vendors",
    component: lazy(() => import("../../view/pages/vendors/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/vendors-details/:id",
    component: lazy(() => import("../../view/pages/vendors/vendorApproval/VendorDetails")),
    layout: "VerticalLayout",
    private: true,
  },
  
  {
    path: "/vendor-approval",
    component: lazy(() => import("../../view/pages/vendors/vendorApproval/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/users",
    component: lazy(() => import("../../view/pages/users/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/employees-subadmins",
    component: lazy(() => import("../../view/pages/users/view/employees-subadmins")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/customers",
    component: lazy(() => import("../../view/pages/customers/view")),
    layout: "VerticalLayout",
    private: true,
  },
  
  {
    path: "/courier",
    component: lazy(() => import("../../view/pages/couriers/view")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/store-settings",
    component: lazy(() => import("../../view/pages/store-settings/view")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/reports",
    component: lazy(() => import("../../view/pages/reports/view")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/account-and-settings",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages/profile/notifications",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages/profile/activity",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages/profile/security",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages/profile/password-change",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages/profile/connect-with-social",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
    private: true,
  },
  {
    path: "/pages/error-page",
    component: lazy(() => import("../../view/pages/error")),
    layout: "FullLayout",
    private: false,
  },
  {
    path: "/pages/:slug",
    component: lazy(() => import("../../view/pages/navigation/index")),
    layout: "HorizontalLayout",
    private: false,
  },
];

export default PagesRoutes;
