
import React, { useState } from "react";
import { Card, Row, Col, DatePicker,Table, Divider } from "antd";
import Chart from "react-apexcharts";
import { RiBarChart2Fill } from "react-icons/ri";
import { BiBarChart } from "react-icons/bi";

export default function MostRecentOrder() {
const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Sales Count',
    dataIndex: 'sales_count',
  },
  {
    title: 'Gross Earnings[AED]',
    dataIndex: 'gross_earnings',
  },
  {
    title: 'Vendor Payout[AED]',
    dataIndex: 'vendor_payout',
  },
  {
    title: 'NET Earnings[AED]',
    dataIndex: 'net_earnings',
  },
];
const orderData  = [
    {
      key: '1',
      date: '2023-01-01',
      sales_count: 50,
      gross_earnings: 12000,
      vendor_payout: 6000,
      net_earnings: 6000,
    },
    {
      key: '2',
      date: '2023-01-02',
      sales_count: 65,
      gross_earnings: 15000,
      vendor_payout: 7500,
      net_earnings: 7500,
    },
    {
      key: '3',
      date: '2023-01-03',
      sales_count: 45,
      gross_earnings: 11000,
      vendor_payout: 5500,
      net_earnings: 5500,
    },
    {
      key: '4',
      date: '2023-01-04',
      sales_count: 80,
      gross_earnings: 20000,
      vendor_payout: 10000,
      net_earnings: 10000,
    },
    {
      key: '5',
      date: '2023-01-05',
      sales_count: 60,
      gross_earnings: 14000,
      vendor_payout: 7000,
      net_earnings: 7000,
    },
  ];
  
const MostRecentOrderTable = () => (
  <div style={{marginTop:'5%'}}>
    <Table columns={columns} dataSource={orderData}  />
    </div>
);
  const [data] = useState({
    series: [
      {
        name: "BOUNCE RATE",
        data: [28956, 21313, 24623, 28935, 27345, 23465],
      },
      {
        name: "PAGE SESSIONS",
        data: [6000, 11313, 14623, 18935, 17345, 13465],
      },
    ],
    options: {
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.1,
        },
      },
      labels: {
        show: false, // Removed x-label
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false, // Removed grid
        borderColor: "#DFE6E9",
        row: {
          opacity: 0.5,
        },
      },
      fill: {
        opacity: 0.7,
        type: "solid",
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        labels: {
          show: false, // Removed x-label
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
        },
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      },
      legend: {
        show: false, // Removed legend
        horizontalAlign: "right",
        offsetX: 40,
        position: "top",
        markers: {
          radius: 12,
        },
      },
      colors: ["#f1f1f1", "#ADD8E6"],
      stroke: {
        show: false, // Removed stroke
        curve: "smooth",
        width: 3,
        lineCap: "butt",
        colors: ["#c4c4c4", "#94c5c3"],
      },
      yaxis: {
        show: false, // Removed y-label
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
          formatter: (value) => {
            return value / 1000 + "K";
          },
        },
        min: 0,
        max: 40000,
        tickAmount: 4,
      },
    },
  });

  return (
    <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Col span={24}>
              <h4 className="hp-mr-8" style={{color:'#39699a', fontWeight:'bold'}} >Most Recent Oder[15 Days]</h4>
            </Col>
            <Col span={24}>
              <p className="hp-mr-8" style={{color:'#aeafb0'}}>
               Your sales and referral earnings over the last 30 days
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <div style={{display:'flex'}}>
          <div style={{background:'#68F1F6', width:'20%',padding:'7px 0px', borderRadius:'4px'}}>
          <BiBarChart color="white" size={28} style={{margin:'5px auto', display:'flex'}} />
          </div>
          <div style={{marginLeft:'14px'}}>
          <p style={{color:"#9e9e9e", fontWeight:'600'}}>Gross Earnings</p>
          <h3 style={{color:"#39699a", lineHeight:'6px', marginTop:'10px'}}>AED 12,958</h3>
        
          </div>
          </div>
        </Col>
        <Col span={8}>
          <div style={{display:'flex'}}>
          <div style={{background:'#F692DB', width:'20%',padding:'7px 0px', borderRadius:'4px'}}>
          <BiBarChart color="white" size={28} style={{margin:'5px auto', display:'flex'}} />
          </div>
          <div style={{marginLeft:'14px'}}>
           <p style={{color:"#9e9e9e", fontWeight:'600'}}>Vendor Payout</p>
           <h3 style={{color:"#39699a", lineHeight:'6px', marginTop:'10px'}}>AED 6344</h3>
        
          </div>
          </div>
        </Col>
        <Col span={8}>
          <div style={{display:'flex'}}>
          <div style={{border:'2.5px #cdcece solid',background:'#cdcece', width:'20%',padding:'7px 0px', borderRadius:'4px'}}>
          <BiBarChart  size={28} color="white" style={{margin:'5px auto', display:'flex'}} />
          </div>
          <div style={{marginLeft:'14px'}}>
          <p style={{color:"#9e9e9e", fontWeight:'600'}}>NET Site Earnings</p>
          <h3 style={{color:"#39699a", lineHeight:'6px', marginTop:'10px'}}>AED 6614</h3>
        
          </div>
          </div>
        </Col>
        <Col span={24}>
        <MostRecentOrderTable />
            </Col>
       
      </Row>
    </Card>
  );
}
