import React, { useEffect, useState } from "react";
import { Card, Row, Col, DatePicker } from "antd";
import Chart from "react-apexcharts";

export default function DeviceSessions() {
  const [data] = useState({
    series: [
      {
        name: "MOBILE",
        data: [
          28877, 29334, 33233, 36439, 32675, 32333,
        ],
      },
      {
        name: "DESKTOP",
        data: [
          18935, 17345, 13465, 17813, 19125, 16256,
        ],
      },
      {
        name: "Other",
        data: [
          17813, 19125, 16256, 20356, 12233, 14570,
        ],
      },
    ],
    options: {
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#458efd", "#1BE7FF", "#8ab9ff"],
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
      ],
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: "#DFE6E9",
        row: {
          opacity: 0.5,
        },
      },
      /* markers: {
        strokeWidth: 0,
        size: 6,
        colors: ["#458efd", "#1BE7FF", "#8ab9ff"],
        hover: {
          sizeOffset: 1,
        },
      }, */
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
        position: "top",
        formatter: function (seriesName, opts) {
          // Calculate and display the total for each series in the legend
          const total = data.series.find((item) => item.name === seriesName).data.reduce((acc, value) => acc + value, 0);
          return seriesName + ": " + total.toFixed(2) + "K";
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
          formatter: (value) => {
            return value / 1000 + "K";
          },
        },
        min: 0,
        max: 40000,
        tickAmount: 4,
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return `<div class="hp-tooltip"><div>${w.globals.labels[dataPointIndex]}</div><div>${series[seriesIndex][dataPointIndex].toFixed(2)}K</div></div>`;
        },
      },
    },
  });
  useEffect(() => {
    const handleResize = () => {
      const chart = document.querySelector("#chart .apexcharts-canvas");
      const svgChart = document.querySelector("#chart #SvgjsSvg1416");
      if(svgChart){
      svgChart.style.width = '504px'
      }
      // #SvgjsSvg1416
      if (chart) {
        const chartWidth = chart.offsetWidth;
        console.log("chartWidth: ", chartWidth)
        const newHeight = Math.min(350, (9 / 16) * chartWidth); // Adjust the aspect ratio as needed
        chart.style.height = `${newHeight}px`;
        chart.style.width = '504px';
      }
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Col span={24}>
              <h4 className="hp-mr-8">Device Sessions</h4>
            </Col>
            {/* <Col span={24}>
              <p className="hp-mr-8">
                Type metric tells you what number and percentage of users are visiting your website on each device at a period of time of your choice
              </p>
            </Col> */}
          </Row>
        </Col>

        <Col span={24}>
          <div id="chart">
            <Chart
              options={data.options}
              series={data.series}
              type="area"
             /*  width={520}
              height={350} */
              legend="legend"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
