import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Col, Divider, Row } from 'antd';

const generateDayWiseTimeSeries = (baseDate, count, range) => {
  const seriesData = [];

  for (let i = 0; i < count; i++) {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + i);

    seriesData.push({
      x: date.getTime(),
      y: Math.floor(Math.random() * (range.max - range.min + 1) + range.min),
    });
  }

  return seriesData;
};

const AccountRetention = () => {
   const BarChart = () => {
      const [chartData] = useState({
        series: [
          {
            name: 'Inflation',
            data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels:{
            enabled: false
          },
          /* dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + '%';
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          }, */
    
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            position: 'botton',
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + '%';
              },
            },
          },
         /*  title: {
            text: 'Monthly Inflation in Argentina, 2002',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444',
            },
          }, */
        },
      });
    
      return (
        <div id="chart">
          <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
      );
    };
    

  return (
    <div id="chart">
      <Card>
        <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>Account Retention</h4>
        <p style={{ color: '#aeafb0' }}>Number of customers who have active subscription with you.</p>
       <BarChart />
        {/* <Divider /> */}
        <Row gutter={[32,32]}>
          <Col span={12}>
            <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>$620,076</h4>
            <p style={{ color: '#39699a', fontWeight: 'bold' }}>Expansion</p>
            <p style={{ color: '#aeafb0' }}>Customers who have upgraded the level of your products or service</p>
          </Col>
          <Col span={12}>
            <h4 style={{ color: '#39699a', fontWeight: 'bold' }}>$620,076</h4>
            <p style={{ color: '#ff4d4d', fontWeight: 'bold' }}>Cancellation</p>
            <p style={{ color: '#aeafb0' }}>Customers who have ended their subscription with you.</p>
          </Col>
        </Row>
         </Card>
    </div>
  );
};

export default AccountRetention;

