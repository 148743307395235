import { Card, Col, Progress, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { RxExternalLink } from "react-icons/rx";
import { SiGooglechrome } from "react-icons/si";
import { SiFirefox } from "react-icons/si";
import { GrSafariOption } from "react-icons/gr";
import { FaEdge } from "react-icons/fa6";
import { SiOpera } from "react-icons/si";
const { Title, Text } = Typography;

const BrowserUsedbyUsers = () => {
    const [topSellingProduct, setTopSellingProduct] = useState([
        {
            id: 1,
            icon: <SiGooglechrome />,
            browser: 'Google Chrome',
            sessions: 13410,
            bounceRate: 50.85,
            conversionRate: 19.55
        },
        {
            id: 2,
            icon: <SiFirefox />,
            browser: 'Mozilla Firefox',
            sessions: 1710,
            bounceRate: 50.85,
            conversionRate: 19.99
        },
        {
            id: 3,
            icon: <GrSafariOption />,
            browser: 'Apple Safari',
            sessions: 3164,
            bounceRate: 56.55,
            conversionRate: 11.55
        },
        {
            id: 4,
            browser: 'Microsfot Edge',
            icon: <FaEdge />,
            sessions: 2672,
            bounceRate: 34.44,
            conversionRate: 23.55
        },
        {
            id: 5,
            icon: <SiOpera />,
            browser: 'Opera',
            sessions: 2307,
            bounceRate: 22.22,
            conversionRate: 44.55
        }
    ])
    return (
        <Card bodyStyle={{ paddingBottom: '38px' }} >
            <Row style={{}}>
                <Col span={24} style={{ margin: '0px', padding: '0px' }}>

                    <Text style={{ fontWeight: '900', color: 'hsla(221, 43%, 11%, 1)', marginBottom: '20%' }}>Browsers Used by Users</Text>

                </Col>
                <Col span={24} style={{ margin: '22px 0px 0px 0px ', padding: '0px', }}>
                    <Row style={{
                        marginTop: '1px',
                    }}>
                        <Col span={3} ></Col>
                        <Col style={{ padding: '0px' }} span={6}>
                            <Text style={{ margin: '0px', color: '#7b7878' }} >Browser </Text>
                        </Col>
                        <Col span={5} style={{ padding: '0px' }}>
                            <Text style={{ margin: '0px',  color: '#7b7878' }} >Sessions </Text>
                        </Col>
                        <Col  span={5}>

                            <Text style={{ margin: '0px',  color: '#7b7878' }} >Bounce Rate </Text>
                        </Col>
                        <Col  span={5}>

                            <Text style={{ margin: '0px',  color: '#7b7878' }} >Conversion Rate </Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ margin: '22px 0px 0px 0px ', padding: '0px', }}>
                    {topSellingProduct.map((topSellingProduct) => {
                        console.log("%c percent value", "background: purple; color: white", topSellingProduct.percent)
                        return (
                            <Row style={{
                                marginTop: '10px',
                            }}>
                                <Col span={3}>{topSellingProduct.icon}</Col>
                                <Col style={{ padding: '0px' }} span={6}>
                                    <Text style={{ margin: '0px', width: '100%', }} >{topSellingProduct.browser} </Text>
                                </Col>
                                <Col span={5} style={{ padding: '0px' }}>
                                    <Text style={{ margin: '0px', color: '#7b7878' }} >{topSellingProduct.sessions} </Text>
                                </Col>
                                <Col  span={5}>

                                    <Text style={{ margin: '0px',  color: '#7b7878' }} >{topSellingProduct.bounceRate} </Text>
                                </Col>
                                <Col  span={5}>

                                    <Text style={{ margin: '0px', color: '#7b7878' }} >{topSellingProduct.conversionRate} </Text>
                                </Col>
                            </Row>

                            // </>
                        )
                    })}
                </Col>
            </Row>
        </Card>
    )
}

export default BrowserUsedbyUsers
