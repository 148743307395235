import React, { useState } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Row, Col, Select, Button, Card } from "antd";

import ActionButton from "./../action";
import PageTitle from "./../page-title";

import SummaryItem from "./widgets/summaryItem";
import OneClickThrough from './widgets/OneClickThrough';
import OneViewThrough from './widgets/OneViewThrough';
import OneConversions from './widgets/OneConversions';
import OneTotalVisits from './widgets/OneTotalVisits';

import WebsiteAudienceMetrics from './widgets/WebsiteAudienceMetrics';
import WebsiteAudienceLocations from './widgets/WebsiteAudienceLocations';

import DeviceSessions from './widgets/DeviceSessions';
import Acquisition from './widgets/Acquisition';
import Vendors from "./widgets/Vendors";
import TotalPageVisit from "./widgets/TotalPageVisit";
import BrowserUsedbyUsers from "./widgets/BrowserUsedbyUsers";

export default function ECommerce() {

  return (
    <Row gutter={[32, 0]}>
      <Col span={24}>
        <Row gutter={[8, 0]} justify="space-between" align="middle">
          <Col span={24} md={12}>
            <PageTitle pageTitle="Dashboard" />
            <PageTitle pageText="Analytics" />
          </Col>
          <Col span={24} md={12}>
            <ActionButton />
          </Col>
        </Row>
      </Col>

      <Col span={24} className="hp-my-32">
        <Row gutter={[32, 12]}>
          <Col span={24}>
            <Row gutter={[12, 32]}>
              <Col xl={6} md={12} span={24}>
                <OneClickThrough />
              </Col>

              <Col xl={6} md={12} span={24}>
                <OneViewThrough />
              </Col>

              <Col xl={6} md={12} span={24}>
                <OneConversions />
              </Col>

              <Col xl={6} md={12} span={24}>
                <OneTotalVisits />
              </Col>
            </Row>
          </Col>

          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col sm={24} lg={16} >
                <WebsiteAudienceMetrics />
              </Col>
              <Col sm={24} lg={8} >
                <WebsiteAudienceLocations />
              </Col>
            </Row>
          </Col>

          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col sm={24} lg={12} >
                <Acquisition />
              </Col>
              <Col sm={24} lg={12} >
                <DeviceSessions />
              </Col>
            </Row>
          </Col>
          <Col span={24} className="hp-overflow-hidden">
          <Row gutter={[12, 12]}>
            <Col sm={24} lg={12} >
               <TotalPageVisit />
              </Col>
              
              <Col sm={24} lg={12}>
               <BrowserUsedbyUsers />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
