import React, { useState } from "react";

import { Card, Row, Col, Typography, Space } from "antd";
import {
  RiArrowRightDownLine,
  RiArrowRightUpLine,
  RiSubtractFill,
} from "react-icons/ri";
import Chart from "react-apexcharts";

const { Title, Paragraph, Text, Link } = Typography;

export default function OneViewThrough() {
  const [data1] = useState({
    series: [
      {
        data: [9, 11, 6, 47, 5, 11, 19],
      },
    ],
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      legend: {
        show: false,
      },
      markers: {
        size: 1,
        strokeColors: "#0063F7",
        strokeOpacity: 0,
      },

      colors: ["#0063F7"],
      stroke: {
        lineCap: "round",
        width: 2,
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
        borderColor: "#B2BEC3",
        strokeDashArray: 7,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 426,
          options: {
            legend: {
              itemMargin: {
                horizontal: 16,
                vertical: 8,
              },
            },
          },
        },
      ],
      yaxis: {
        show: false,
      },
    },
  });

  return (
    <Card
      className="hp-border-color-black-40"
      bodyStyle={{paddingLeft:16, paddingRight:16, paddingBottom:16}}
    >
      <Row>
        <Col className="hp-mb-0" span={24}>
          <Row justify="space-between" align="middle">
            <h5 className="hp-mb-0">View Through</h5>
          </Row>
        </Col>

        <Col span={24}>
          <Row align="middle">
            <Col span={16}>
              <Chart
                options={data1.options}
                series={data1.series}
                type="line"
                legend="legend"
                height={100}
                style={{maxHeight:110}}
              />
            </Col>

            <Col span={8}>
              <Row align="middle" justify="end">

                <h4 className="hp-text-color-dark-0 hp-mb-0">108</h4>
                <h5 className="hp-mb-0">89.5%</h5>
                <RiArrowRightUpLine
                  className="hp-text-color-primary-1"
                  size={12}
                />
              </Row>
            </Col>

          </Row>
        </Col>
      </Row>
    </Card>
  );
}