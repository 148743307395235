import React from "react";

import { Col, Row } from "antd";

export default function PageTitle(props) {
  const { pageTitle, pageText } = props;

  return (
    <Row gutter={[8, 0]}>
      <Col span={24}>
        {pageTitle && <h1 className="hp-mb-8">{pageTitle}</h1>}
        {pageText && <p className="hp-mb-0 hp-mb-0 hp-p1-body hp-font-weight-700">{pageText}</p>}
      </Col>
    </Row>
  );
}
