import { Card, Col, Progress, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { RxExternalLink } from "react-icons/rx";
const { Title, Text } = Typography;
const TotalPageVisit = () => {
    const [topSellingProduct, setTopSellingProduct] = useState([
        {
            id: 1,
            product: 'Homepage',
            value: 4694,
            percent: (4694 / 4694) * 100,
            color: '#3bc0c9'
        },
        {
            id: 2,
            product: "Our Services",
            value: 3653,
            percent: (3653 / 4694) * 100,
            color: '#4f5454'
        },
        {
            id: 3,
            product: "List of Products",
            value: 3164,
            percent: (3164 / 4694) * 100,
            color: '#ccc85c'
        },
        {
            id: 4,
            product: 'Contact us',
            value: 2672,
            percent: (2672 / 4694) * 100,
            color: '#faa0f7'
        },
        {
            id: 5,
            product: 'Product 50% sale',
            value: 2307,
            percent: (2307 / 4694) * 100,
            color: '#3bc0c9'
        }
    ])
    return (
        <Card
            bodyStyle={{ paddingBottom: '38px' }}
        >
            <Row style={{}}>
                <Col span={24} style={{ margin: '0px', padding: '0px' }}>

                    <Text style={{ fontWeight: '900', color: 'hsla(221, 43%, 11%, 1)', marginBottom: '20%' }}>Top Page Visits</Text>

                </Col>
                <Col span={24}  style={{ margin: '22px 0px 0px 0px ', padding: '0px', }}>
                <Row style={{
                                marginTop: '1px',
                            }}>
                                <Col span={3} style={{color:'#7b7878'}}>Link</Col>
                                <Col style={{ padding: '0px' }} span={6}>
                                    <Text style={{ margin: '0px', width: '100%', color:'#7b7878' }} >Page Title </Text>
                                </Col>
                                <Col span={11} style={{ padding: '0px' }}>
                                <Text style={{ margin: '0px', width: '100%', color:'#7b7878' }} >Percentage (%) </Text>
                                </Col>
                                <Col style={{ textAlign: 'right' }} span={3}>

                                <Text style={{ margin: '0px', width: '100%', color:'#7b7878' }} >Value </Text>
                                </Col>
                            </Row>
                </Col>
                <Col span={24} style={{ margin: '22px 0px 0px 0px ', padding: '0px', }}>
                    {topSellingProduct.map((topSellingProduct) => {
                        console.log("%c percent value", "background: purple; color: white", topSellingProduct.percent)
                        return (
                             <Row style={{
                                marginTop: '1px',
                            }}>
                                <Col span={3}><RxExternalLink /></Col>
                                <Col style={{ padding: '0px' }} span={6}>
                                    <Text style={{ margin: '0px', width: '100%' }} >{topSellingProduct.product}  </Text>
                                </Col>
                                <Col span={11} style={{ padding: '0px' }}>
                                    <Progress strokeColor={topSellingProduct.color} percent={topSellingProduct.percent} showInfo={false} strokeWidth={8} />
                                </Col>
                                <Col style={{ textAlign: 'right' }} span={3}>

                                    <p>{topSellingProduct.value}%</p>
                                </Col>
                            </Row>

                            // </>
                        )
                    })}
                </Col>
            </Row>
        </Card>
    )
}

export default TotalPageVisit
