import React, { useEffect, useState } from "react";
import { Card, Row, Col, DatePicker } from "antd";
import Chart from "react-apexcharts";
import { RiBarChart2Fill } from "react-icons/ri";
import { BiBarChart } from "react-icons/bi";

export default function Acquisition() {
  const [data] = useState({
    series: [
      {
        name: "BOUNCE RATE",
        data: [28956, 21313, 24623, 28935, 27345, 23465],
      },
      {
        name: "PAGE SESSIONS",
        data: [6000, 11313, 14623, 18935, 17345, 13465],
      },
    ],
    options: {
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.1,
        },
      },
      labels: {
        show: false, // Removed x-label
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false, // Removed grid
        borderColor: "#DFE6E9",
        row: {
          opacity: 0.5,
        },
      },
      fill: {
        opacity: 0.7,
        type: "solid",
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        labels: {
          show: false, // Removed x-label
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
        },
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      },
      legend: {
        show: false, // Removed legend
        horizontalAlign: "right",
        offsetX: 40,
        position: "top",
        markers: {
          radius: 12,
        },
      },
      colors: ["#f1f1f1", "#ADD8E6"],
      stroke: {
        show: false, // Removed stroke
        curve: "smooth",
        width: 3,
        lineCap: "butt",
        colors: ["#c4c4c4", "#94c5c3"],
      },
      yaxis: {
        show: false, // Removed y-label
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
          formatter: (value) => {
            return value / 1000 + "K";
          },
        },
        min: 0,
        max: 40000,
        tickAmount: 4,
      },
    },
  });
  useEffect(() => {
    const handleResize = () => {
      const chart = document.querySelector("#chart .apexcharts-canvas");
      const svgChart = document.querySelector("#chart #SvgjsSvg1353");
      if(svgChart){
      svgChart.style.width = '504px'
      }
      // #SvgjsSvg1416
      if (chart) {
        const chartWidth = chart.offsetWidth;
        console.log("chartWidth: ", chartWidth)
        const newHeight = Math.min(350, (9 / 16) * chartWidth); // Adjust the aspect ratio as needed
        chart.style.height = `${newHeight}px`;
        chart.style.width = '504px';
      }
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Col span={24}>
              <h4 className="hp-mr-8" style={{color:'#39699a', fontWeight:'bold'}} >Acquisition</h4>
            </Col>
            <Col span={24}>
              <p className="hp-mr-8" style={{color:'#aeafb0'}}>
                Tells you where your visitors originated from, such as search engines, social networks, or website referrals.
                Learn more
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div style={{display:'flex'}}>
          <div style={{border:'2.5px #cdcece solid',height:'fit-content', width:'20%',padding:'7px 0px', borderRadius:'4px'}}>
          <BiBarChart  size={28} style={{margin:'auto', display:'flex'}} />
          </div>
          <div style={{marginLeft:'14px'}}>
          <h3 style={{color:"#39699a", lineHeight:'6px', marginTop:'10px'}}>33.50%</h3>
          <p style={{color:"#9e9e9e", fontWeight:'600'}}> Bounce Rate</p>
          </div>
          </div>
        </Col>
        <Col span={12}>
          <div style={{display:'flex'}}>
          <div style={{border:'2.5px #cdcece solid',height:'fit-content', width:'20%',padding:'7px 0px', borderRadius:'4px'}}>
          <BiBarChart  size={28} style={{margin:'auto', display:'flex'}} />
          </div>
          <div style={{marginLeft:'14px'}}>
          <h3 style={{color:"#39699a", lineHeight:'6px', marginTop:'10px'}}>33.50%</h3>
          <p style={{color:"#9e9e9e", fontWeight:'600'}}> Page Session</p>
          </div>
          </div>
        </Col>
        <Col span={24}>
          <div id="chart" >
            <Chart
              options={data.options}
              series={data.series}
              type="area"
              /* width={450}
              height={350} */
              // legend="legend"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
