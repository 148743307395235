import React from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Col, Row, Input, Select, Button } from "antd";

export default function ActionButton() {
  return (
    <>
      <Row gutter={[6, 6]} justify="end">
        <Col>
          <Link to="/dt-analytics">
          <Button type="dashed" size="small" style={{float:'right'}} 
          className="hp-px-12 hp-text-color-black-100 hp-border-color-black-100 hp-hover-text-color-black-80 hp-hover-border-color-black-80">
            Analytics
          </Button>
          </Link>
        </Col>

        <Col>
          <Link to="/dt-sales">
          <Button type="dashed" size="small" style={{float:'right'}} 
          className="hp-px-12 hp-text-color-black-100 hp-border-color-black-100 hp-hover-text-color-black-80 hp-hover-border-color-black-80">
            Sales
          </Button>
          </Link>
        </Col>

        <Col>
          <Link to="/dt-vendors">
          <Button type="dashed" size="small" style={{float:'right'}} 
          className="hp-px-12 hp-text-color-black-100 hp-border-color-black-100 hp-hover-text-color-black-80 hp-hover-border-color-black-80">
            Vendors
          </Button>
          </Link>
        </Col>
        
        {/* <Col>
          <Link to="/dt-helpdesk">
          <Button type="dashed" size="small" style={{float:'right'}} 
          className="hp-px-12 hp-text-color-black-100 hp-border-color-black-100 hp-hover-text-color-black-80 hp-hover-border-color-black-80">
            Helpdesk
          </Button>
          </Link>
        </Col> */}

        <Col>
          <Select
            style={{float:'right', width:'120px'}}
            defaultValue="this-mounth"
            id="month-select"
            size="small"
            className="hp-mt-md-0 hp-mt-lg-0"
          >
            <Select.Option value="this-mounth">Last 30 Days</Select.Option>

            <Select.Option value="this-biweekly">Last 14 Days</Select.Option>

            <Select.Option value="this-week">Last 7 Days</Select.Option>
          </Select>
        </Col>

      </Row>
  </>
  );
}
