import React, { useState } from "react";

import { Card, Row, Col, Divider, Progress, DatePicker } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";

export default function WebsiteAudienceMetrics () {
  const [data] = useState({
    series: [
      {
        name: "New Users",
        data: [
          18766, 16574, 13423, 15646, 18745, 16343
        ],
      },
      {
        name: "Page Views",
        data: [
          12010, 11313, 14623, 18935, 17345, 13465
        ],
      },
      {
        name: "Page Sessions",
        data: [
          13435, 13445, 13454, 14346, 15446, 13254
        ],
      },
      {
        name: "Bounce Rate",
        data: [
          12010, 11313, 14623, 18935, 17345, 13465
        ],
      },
    ],
    options: {
      chart: {
        id: "revenue-column-card",
        fontFamily: "Manrope, sans-serif",
        type: "bar",
        

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      labels: {
        style: {
          fontSize: "14px",
        },
      },

      dataLabels: {
        enabled: false,
      },

      grid: {
        borderColor: "#DFE6E9",
        row: {
          opacity: 0.5,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
          //columnWidth: "45%",
          endingShape: "rounded",
        },
        colors: {
          backgroundBarColors: ["#0063F7", "#00F7BF"],
        },
      },

      stroke: {
        show: true,
        width: 4,
        colors: ["transparent"],
      },
      xaxis: {
        axisTicks: {
          show: false,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },

        tickPlacement: "between",
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
        ],
      },
      legend: {
        horizontalAlign: "right",
        //offsetX: 40,
        position: "top",
        markers: {
          radius: 12,
        },
        
      },
      yaxis: {
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
          formatter: (value) => {
            return value / 1000 + "K";
          },
        },

        min: 0,
        max: 20000,
        tickAmount: 4,
      },
    },
  });

  return (
    <Card className="hp-border-color-black-40 hp-card-6 hp-chart-text-color">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Website Audience Metrics</h4>
            </Row>
          </Row>
        </Col>

        <Col span={20}>
          <div id="revenue-column-card">
            <Chart
              options={data.options}
              series={data.series}
              type="bar"
              width='95%'
              //height="100%"
              legend="legend"
            />
          </div>
        </Col>
      </Row>
      
        <Row gutter={[24, 10]} justify="space-between">
        <Divider className="hp-mt-24 hp-mb-12" />
          <Col span={24} md={12} className="hp-mb-16">
            <p className="hp-mb-0 hp-p1-body">NEW USERS</p>
            <Progress percent={24} strokeColor={'deepskyblue'} />
          </Col>
          <Col span={24} md={12} className="hp-mb-16">
            <p className="hp-mb-0 hp-p1-body">PAGE VIEWS</p>
            <Progress percent={87} strokeColor={'darkturquoise'}/>
          </Col>
          <Col span={24} md={12} className="hp-mb-16">
            <p className="hp-mb-0 hp-p1-body">PAGE SESSIONS</p>
            <Progress percent={47} strokeColor={'goldenrod'}/>
          </Col>
          <Col span={24} md={12} className="hp-mb-16">
            <p className="hp-mb-0 hp-p1-body">BOUNCE RATE</p>
            <Progress percent={10} strokeColor={'indianred'}/>
          </Col>
        </Row>
    </Card>
  );
}
