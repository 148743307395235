import React, { useState } from "react";

import { Card, Row, Col, Divider } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";

export default function SalesCard() {
  const [data] = useState({
    series: [
      {
        name: "Sales",
        data: [
          28877, 29334, 33233, 36439, 32675, 32333, 33457,
        ],
      },
    ],
    options: {
      chart: {
        id: "revenue-column-card",
        fontFamily: "Manrope, sans-serif",
        type: "bar",

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      labels: {
        style: {
          fontSize: "12px",
        },
      },

      dataLabels: {
        enabled: false,
      },

      grid: {
        borderColor: "#DFE6E9",
        row: {
          opacity: 0.5,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
          columnWidth: "30%",
          endingShape: "rounded",
        },
        colors: {
          backgroundBarColors: ["#0063F7", "#00F7BF"],
        },
      },

      stroke: {
        show: true,
        width: 4,
        colors: ["transparent"],
      },
      xaxis: {
        axisTicks: {
          show: false,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },

        tickPlacement: "between",
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "14px",
          },
        },
        categories: [
          "Jan 1",
          "Jan 2",
          "Jan 3",
          "Jan 4",
          "Jan 5",
          "Jan 6",
          "Jan 7",
        ],
      },
      legend: {
        horizontalAlign: "right",
        offsetX: 40,
        position: "top",
        markers: {
          radius: 12,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ["636E72"],
            fontSize: "12px",
          },
          formatter: (value) => {
            return value / 1000 + "K";
          },
        },

        min: 0,
        max: 40000,
        tickAmount: 4,
      },
    },
  });

  return (
    <Card className="hp-border-color-black-40 hp-card-3 hp-chart-text-color">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Sales</h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <div id="revenue-column-card" style={{minHeight:"180px"}}>
            <Chart
              options={data.options}
              series={data.series}
              type="bar"
              height="100%"
              legend="legend"
            />
          </div>
        </Col>

        <Col span={24}>
        <Divider className="hp-my-12 hp-color-black-10"  justify="space-between" />
            <Row>
              <Col md={12} span={12}>Today</Col>
              <Col md={12} span={12} className="hp-text-right">289.55</Col>
            </Row>
          <Divider className="hp-my-12 hp-color-black-10"  justify="space-between" />
            <Row>
              <Col md={12} span={12}>Last 30 days</Col>
              <Col md={12} span={12} className="hp-text-right">
                <span className="hp-badge-text hp-text-color-success-4 hp-mr-8">+3%</span>
                3589.55</Col>
            </Row>
            {/* <Divider className="hp-my-12 hp-color-black-10"  justify="space-between" />
            <Row>
              <Col md={12} span={12}>Last 14 days</Col>
              <Col md={12} span={12} className="hp-text-right">14189.21</Col>
            </Row> */}
        </Col>

      </Row>
    </Card>
  );
}
